.ant-layout-footer{
    background-color: #34495E;
    color: #fff;
    font-family:  -apple-system,BlinkMacSystemFont,Segoe UI,Roboto,Oxygen,Ubuntu,Cantarell,Fira Sans,Droid Sans,Helvetica Neue,sans-serif;
    font-size: 1.2rem;
}

.ant-spin-dot-item{
  background-color: #008000 !important;
  font-size: 200px;
}



.ant-checkbox-checked .ant-checkbox-inner {
    background-color: #008000 !important;
    border-color:  #008000 !important;
    border-color: #008000 !important;
    outline-color: #008000 !important;
  }


  .ant-radio-checked .ant-radio-inner {
    background-color: #008000 !important;
    border-color:  #008000 !important;
    border-color: #008000 !important;
    outline-color: #008000 !important;
  }



