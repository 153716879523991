/* :root { 
  --black: #000000;
  --chateau-green: #4aad5e33;
  --color1: #4aad5e;
  --d9d9d9: #bf4b51;
  --desert-storm: #f8f8f8;
  --e0dfe8: #e0dfe8;
  --log-cabin: #22222280;
  --mine-shaft: #333333;
  --paragraph: #888888;
  --racing-green: #0b180e;
  --white: #ffffff;
  --x222222: #222222;
 
  --font-size-l: 36px;
  --font-size-m: 24px;
  --font-size-s: 20px;
  --font-size-xl: 93.6px;
  --font-size-xs: 17.2px;
  --font-size-xxs: 14px;
  --font-size-xxxs: 12px;
 
  --font-family-mulish: "Mulish", Helvetica;
  --font-family-nunito_sans: "Nunito Sans", Helvetica;
}
.nunitosans-bold-log-cabin-20px {
  color: var(--x222222);
  font-family: var(--font-family-nunito_sans);
  font-size: var(--font-size-s);
  font-style: normal;
  font-weight: 700;
}

.nunitosans-normal-white-24px {
  color: var(--white);
  font-family: var(--font-family-nunito_sans);
  font-size: var(--font-size-m);
  font-style: normal;
  font-weight: 400;
}

.mulish-bold-mine-shaft-17-2px {
  color: var(--mine-shaft);
  font-family: var(--font-family-mulish);
  font-size: var(--font-size-xs);
  font-style: normal;
  font-weight: 700;
}

.nunitosans-normal-suva-gray-12px {
  color: var(--paragraph);
  font-family: var(--font-family-nunito_sans);
  font-size: var(--font-size-xxxs);
  font-style: normal;
  font-weight: 400;
}

.nunitosans-bold-log-cabin-36px {
  color: var(--x222222);
  font-family: var(--font-family-nunito_sans);
  font-size: var(--font-size-l);
  font-style: normal;
  font-weight: 700;
}

.nunitosans-bold-white-93-6px {
  font-family: var(--font-family-nunito_sans);
  font-size: var(--font-size-xl);
  font-style: normal;
  font-weight: 700;
}

.nunitosans-bold-black-93-6px {
  color: var(--black);
  font-family: var(--font-family-nunito_sans);
  font-size: var(--font-size-xl);
  font-style: normal;
  font-weight: 700;
}

.nunitosans-bold-desert-storm-141-4px {
  color: var(--desert-storm);
  font-family: var(--font-family-nunito_sans);
  font-size: 141.4px;
  font-style: normal;
  font-weight: 700;
}

.mulish-bold-log-cabin-17-2px {
  color: var(--x222222);
  font-family: var(--font-family-mulish);
  font-size: var(--font-size-xs);
  font-style: normal;
  font-weight: 700;
}

.mulish-bold-chateau-green-17-2px {
  color: var(--color1);
  font-family: var(--font-family-mulish);
  font-size: var(--font-size-xs);
  font-style: normal;
  font-weight: 700;
}

.nunitosans-bold-white-36px {
  color: var(--white);
  font-family: var(--font-family-nunito_sans);
  font-size: var(--font-size-l);
  font-style: normal;
  font-weight: 700;
}

.nunitosans-bold-white-20px {
  color: var(--white);
  font-family: var(--font-family-nunito_sans);
  font-size: var(--font-size-s);
  font-style: normal;
  font-weight: 700;
}

.nunitosans-normal-white-14px {
  color: var(--white);
  font-family: var(--font-family-nunito_sans);
  font-size: var(--font-size-xxs);
  font-style: normal;
  font-weight: 400;
} */
