@import "https://cdnjs.cloudflare.com/ajax/libs/meyer-reset/2.0/reset.min.css";
@import "https://fonts.googleapis.com/css?family=Nunito+Sans:400,700|Mulish:700";
@import "https://px.animaapp.com/63dceafe456b67eb82e174a9.63dceafff33f94e3fc2747c0.jNBdLQf.rcp.png";
.screen a {
  text-decoration: none;
  display: contents;
}

.container-center-horizontal {
  pointer-events: none;
  width: 100%;
  flex-direction: row;
  justify-content: center;
  display: flex;
}

.container-center-horizontal > * {
  pointer-events: auto;
  flex-shrink: 0;
}

.valign-text-middle {
  flex-direction: column;
  justify-content: center;
  display: flex;
}

* {
  box-sizing: border-box;
}

.ant-layout-footer {
  color: #fff;
  background-color: #34495e;
  font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen, Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif;
  font-size: 1.2rem;
}

.ant-spin-dot-item {
  font-size: 200px;
  background-color: green !important;
}

.ant-checkbox-checked .ant-checkbox-inner, .ant-radio-checked .ant-radio-inner {
  background-color: green !important;
  border-color: green !important;
  outline-color: green !important;
}

.navbar {
  justify-content: center;
  display: flex;
}

html, body {
  width: 100%;
  height: 100%;
}

input::-ms-clear, input::-ms-reveal {
  display: none;
}

*, :before, :after {
  box-sizing: border-box;
}

html {
  -webkit-text-size-adjust: 100%;
  -ms-text-size-adjust: 100%;
  -ms-overflow-style: scrollbar;
  -webkit-tap-highlight-color: #0000;
  font-family: sans-serif;
  line-height: 1.15;
}

@-ms-viewport {
  width: device-width;
}

body {
  margin: 0;
}

[tabindex="-1"]:focus {
  outline: none;
}

hr {
  box-sizing: content-box;
  height: 0;
  overflow: visible;
}

h1, h2, h3, h4, h5, h6 {
  margin-top: 0;
  margin-bottom: .5em;
  font-weight: 500;
}

p {
  margin-top: 0;
  margin-bottom: 1em;
}

abbr[title], abbr[data-original-title] {
  -webkit-text-decoration: underline dotted;
  cursor: help;
  border-bottom: 0;
  -webkit-text-decoration: underline dotted;
  text-decoration: underline dotted;
}

address {
  font-style: normal;
  line-height: inherit;
  margin-bottom: 1em;
}

input[type="text"], input[type="password"], input[type="number"], textarea {
  -webkit-appearance: none;
}

ol, ul, dl {
  margin-top: 0;
  margin-bottom: 1em;
}

ol ol, ul ul, ol ul, ul ol {
  margin-bottom: 0;
}

dt {
  font-weight: 500;
}

dd {
  margin-bottom: .5em;
  margin-left: 0;
}

blockquote {
  margin: 0 0 1em;
}

dfn {
  font-style: italic;
}

b, strong {
  font-weight: bolder;
}

small {
  font-size: 80%;
}

sub, sup {
  vertical-align: baseline;
  font-size: 75%;
  line-height: 0;
  position: relative;
}

sub {
  bottom: -.25em;
}

sup {
  top: -.5em;
}

pre, code, kbd, samp {
  font-family: SFMono-Regular, Consolas, Liberation Mono, Menlo, Courier, monospace;
  font-size: 1em;
}

pre {
  margin-top: 0;
  margin-bottom: 1em;
  overflow: auto;
}

figure {
  margin: 0 0 1em;
}

img {
  vertical-align: middle;
  border-style: none;
}

a, area, button, [role="button"], input:not([type="range"]), label, select, summary, textarea {
  touch-action: manipulation;
}

table {
  border-collapse: collapse;
}

caption {
  text-align: left;
  caption-side: bottom;
  padding-top: .75em;
  padding-bottom: .3em;
}

input, button, select, optgroup, textarea {
  color: inherit;
  font-size: inherit;
  font-family: inherit;
  line-height: inherit;
  margin: 0;
}

button, input {
  overflow: visible;
}

button, select {
  text-transform: none;
}

button, html [type="button"], [type="reset"], [type="submit"] {
  -webkit-appearance: button;
}

button::-moz-focus-inner, [type="button"]::-moz-focus-inner, [type="reset"]::-moz-focus-inner, [type="submit"]::-moz-focus-inner {
  border-style: none;
  padding: 0;
}

input[type="radio"], input[type="checkbox"] {
  box-sizing: border-box;
  padding: 0;
}

input[type="date"], input[type="time"], input[type="datetime-local"], input[type="month"] {
  -webkit-appearance: listbox;
}

textarea {
  resize: vertical;
  overflow: auto;
}

fieldset {
  min-width: 0;
  border: 0;
  margin: 0;
  padding: 0;
}

legend {
  width: 100%;
  max-width: 100%;
  color: inherit;
  font-size: 1.5em;
  line-height: inherit;
  white-space: normal;
  margin-bottom: .5em;
  padding: 0;
  display: block;
}

progress {
  vertical-align: baseline;
}

[type="number"]::-webkit-inner-spin-button, [type="number"]::-webkit-outer-spin-button {
  height: auto;
}

[type="search"] {
  outline-offset: -2px;
  -webkit-appearance: none;
}

[type="search"]::-webkit-search-cancel-button, [type="search"]::-webkit-search-decoration {
  -webkit-appearance: none;
}

::-webkit-file-upload-button {
  font: inherit;
  -webkit-appearance: button;
}

output {
  display: inline-block;
}

summary {
  display: list-item;
}

template {
  display: none;
}

[hidden] {
  display: none !important;
}

mark {
  background-color: #feffe6;
  padding: .2em;
}

body, p, h3, h2 {
  font-family: Nunito Sans, sans-serif;
}



/*# sourceMappingURL=index.e6296f0d.css.map */
